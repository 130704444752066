<template>
	<Layout>
		<Header>
			<b-button v-if="permissionEnabled('roles', 'create')" type="is-primary create" rounded @click="createRole($event)">
				<svg-icon icon="plus" class="icon is-small"></svg-icon>
				<span>Agregar</span>
			</b-button>
		</Header>
		<section v-if="!errored" class="filter">
			<div class="filter__wrapper">
				<b-field label="Orden" v-model="order" :label-position="label">
					<b-select placeholder="Nombre" @input="filterBy">
						<option selected value="1">Nombre</option>
						<option value="2">Fecha</option>
					</b-select>
				</b-field>
				<b-field>
					<b-input placeholder="Buscar" type="search" icon-right="magnify" @input="findByName" v-model="role.name"></b-input>
				</b-field>
			</div>
		</section>
		<Error v-if="errored" :back="true" />
		<Results v-if="permission.length == 0 && !loading" />
		<div v-if="loading" class="columns is-multiline">
			<div v-for="r in placeholder" :key="r" class="column is-12-mobile is-6-tablet is-4-desktop">
				<Placeholder />
			</div>
		</div>
		<transition-group name="filtering" class="filtering columns is-multiline" tag="div">
			<div v-for="r in permission" :key="r.id" class="list-item column is-12-mobile is-6-tablet is-4-desktop">
				<article class="block" :style="{ 'border-left-color': r.color }" @click.self="updateRole(r.id)">
					<div class="block__content" @click="updateRole(r.id)">
						<h3 class="block__name" translate="no">{{ r.name }}</h3>
						<p class="block__email">{{ format(r.created_at) }} • {{ timeTo(r.created_at) }}</p>
					</div>
					<Trigger :id="r.id" :permission="r.slug" />
				</article>
			</div>
		</transition-group>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Icon from '@/components/Icon'
import Placeholder from '@/components/placeholders/Role'
import Trigger from '@/components/triggers/Role'
import Error from '@/components/Error'
import Results from '@/components/Results'
import Modal from '@/components/modals/Role'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { create, update } from '@/mixins/modal'
import { successToast } from '@/mixins/toast'
import { mapGetters } from 'vuex'
import '@/mixins/date'
import actionUrl from '@/mixins/actionurl'

export default {
	components: {
		Layout,
		Header,
		Placeholder,
		Trigger,
		Error,
		Results,
		'svg-icon': Icon
	},
	mixins: [
		actionUrl
	],
	data() {
		return {
			placeholder: 3,
			loading: true,
			errored: false,
			// Filter
			order: 1,
			label: 'on-border',
			permission: [],
			user: {},
			role: {
				name: ''
			}
		}
	},
	methods: {
		async getAllRoles() {
			try {
				const response = await Api.get('permission/findAll')
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.permission = data
					// this.permission.route = JSON.parse(data.route)
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		filterBy(e) {
			const type = parseInt(e)
			type === 1 ? this.getAllRoles() : this.findByDate()
		},
		async findByDate() {
			try {
				const response = await Api.get('permission/findAllOrderByDate')
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.permission = data
					this.permission.route = JSON.parse(data.route)
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async findByName() {
			try {
				const empty = /^\s*$/
				if (!empty.test(this.role.name)) {
					const response = await Api.post('permission/findByName', {
						name: this.role.name
					})
					const { status } = response
					if (status === 200) {
						const { data } = response
						this.permission = data
						this.permission.route = JSON.parse(data.route)
					}
				} else {
					await this.getAllRoles()
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		getUrlParams() {
			const url = location.href.split('/')
			const params = url[4]
			const type = url.find(el => el === params)
			switch (type) {
				case 'create':
					this.createRole()
					break
				case 'edit':
					this.updateRole(url[5])
			}
		},
		createRole() {
			if (this.permissionEnabled('roles', 'create')) {
				create('roles', Modal, 'New')
			}
		},
		updateRole(id) {
			if (this.permissionEnabled('roles', 'edit')) {
				update('roles', id, Modal, 'Edit')
			}
		}
	},
	mounted() {
		this.getAllRoles()
		this.getUrlParams()
		eventHub.$on('edit-modal-role', obj => {
			update('roles', obj.id, Modal, 'Edit')
		})
		eventHub.$on('reload-roles', () => {
			this.getAllRoles()
		})
		eventHub.$on('delete-role', obj => {
			this.$buefy.dialog.alert({
				size: 'is-delete',
				type: 'is-outlined is-primary',
				title: '¡Atención!',
				message: '<span>¿Realmente desea <strong>eliminar</strong> este perfil de usuario?</span> <small>Esta acción no se puede deshacer y tiene un impacto diferente en el funcionamiento del sistema.</small>',
				canCancel: true,
				focusOn: 'cancel',
				cancelText: 'No',
				confirmText: 'Sí',
				onConfirm: async () => {
					try {
						const response = await Api.delete(`permission/destroy/${obj.id}`)
						const { status } = response
						if (status === 200) {
							// const { message } = response.data
							// console.log(message)
							successToast('¡El perfil ha sido <strong>eliminado</strong> exitosamente!')
							await this.getAllRoles()
						}
					} catch (e) {
						console.log(e)
					}
				}
			})
		})
	},
	destroyed() {
		eventHub.$off('edit-modal-role')
		eventHub.$off('reload-roles')
		eventHub.$off('delete-role')
	},
	computed: {
		...mapGetters('user', ['permissionEnabled'])
	}
}
</script>
